import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader';

import Load from '../loadable';

// import SurahsRoute from './Surahs';
// import SurahRoute from './Surah';
// import CollectionRoute from './Collection';
// // import JuzsRoute from './Juzs';
// import CollectionsRoute from './Collections';
// import TopicsRoute from './Topics';
// import StatsRoute from './Stats';
// import NotFoundRoute from './NotFound';

const SurahsRoute = Load(() => import(/* webpackChunkName: 'Surahs' */ './Surahs'));
const SurahRoute = Load(() => import(/* webpackChunkName: 'Surah' */ './Surah'));
const CollectionRoute = Load(() => import(/* webpackChunkName: 'Collection' */ './Collection'));
// const JuzsRoute = Load(() => import(/* webpackChunkName: 'Juzs' */ './Juzs'));
const CollectionsRoute = Load(() => import(/* webpackChunkName: 'Collections' */ './Collections'));
const TopicsRoute = Load(() => import(/* webpackChunkName: 'Topics' */ './Topics'));
const StatsRoute = Load(() => import(/* webpackChunkName: 'Stats' */ './Stats'));
const NotFoundRoute = Load(() => import(/* webpackChunkName: 'NotFound' */ './NotFound'));

class RouterComp extends Component {
  componentDidMount() {
    console.log('Router started.');
  }

  render() {
    return (
      <Switch>
        <Route
          exact
          path="/"
          component={SurahsRoute}
        />
        <Route
          exact
          path="/juz/:juz"
          component={SurahsRoute}
        />
        <Route
          exact
          path="/type/:type"
          component={SurahsRoute}
        />
        <Route
          exact
          path="/surah-lazim"
          component={SurahsRoute}
        />
        <Route
          exact
          path="/(tafsir|reader|single)/:surah/:verse?"
          component={SurahRoute}
        />
        <Route
          exact
          path="/(collection|topic)/:identifier"
          component={CollectionRoute}
        />
        {/* <Route
          exact
          path="/juzs"
          component={JuzsRoute}
        /> */}
        <Route
          exact
          path="/collections"
          component={CollectionsRoute}
        />
        <Route
          exact
          path="/topics"
          component={TopicsRoute}
        />
        <Route
          exact
          path="/stats"
          component={StatsRoute}
        />

        {/* 404 route */}
        <Route component={NotFoundRoute} />
      </Switch>
    );
  }
}

export default hot(module)(RouterComp);
