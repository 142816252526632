import { injectGlobal, css } from 'styled-components';
import { mix } from 'polished';
import { theme, media, colors } from './theme';

const globalStyles = css`
  ::-moz-selection {
    background: ${theme.colors.primary};
    color: #fff;
  }
  ::selection {
    background: ${theme.colors.primary};
    color: #fff;
  }

  [unselectable], .unselectable {
    user-select: none;
  }

  body, .smooth-container { scroll-behavior: smooth }

  html {
    overflow-x: initial;
    overflow-y: initial;
  }

  html, body {
    min-height: 100vh;

    ${media.tablet`
      overflow-x: hidden;
    `}
  }

  body {
    background: #fff;
  }

  body.dark {
    background: ${mix(0.8, colors.pr.black, '#000')};
    color: ${colors.pr.gray400};

    a:hover {
      color: ${colors.pr.white};
    }

    .input, .textarea {
      background: ${colors.pr.black};
      border-color: ${colors.pr.gray900};
      color: ${colors.pr.gray400};

      &::placeholder {
        color: ${colors.pr.gray600};
      }
    }
  }

  h1, h2, h3, h4 {
    font-family: ${theme.familySansSerifHeader};
  }

  [lang="ar"], .arabic {
    font-family: 'KFGQPC Uthman Taha Naskh' !important;
  }

  .arabic-uthman {
    font-family: 'KFGQPC Uthman Taha Naskh' !important;
  }

  .arabic-mequran {
    font-family: 'me quran' !important;
  }

  .ltr { direction: ltr; }
  .rtl { direction: rtl; }

  [class*=" surahname-"], [class^=surahname-] {
    margin-left: -.3em;
    margin-bottom: -.15em;
  }

  .transliteration {
    font-family: ${theme.familySerif};
  }

  .topics {
    line-height: 1.2;

    .topic {
      font-size: .75rem;
      text-transform: uppercase;
      letter-spacing: .05em;
      font-weight: 600;
      color: ${theme.colors.surahnumber};
      margin-right: 1em;

      .title {
        &:before {
          content: "#";
        }
      }
    }
  }

  .svg-pause {
    height: .75rem;
    path {
      fill: ${theme.colors.symbols};
    }
  }

  .svg-no-stop path {
    fill: ${theme.colors.pauseNoStop};
  }

  .svg-must-stop path {
    fill: ${theme.colors.pauseMustStop};
  }

  .svg-short path {
    fill: ${theme.colors.pauseShort};
  }

  .svg-no-hl path {
    fill: ${colors.pr.gray500};
  }

  .overflow-scrolling {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .switch[type="checkbox"].is-small + label {
    padding-top: 0.1rem;
  }

  body.dark {
    .switch[type="checkbox"].is-small + label:before,
    .switch[type="checkbox"].is-small + label:before {
      background: ${colors.pr.gray700};
    }
  }

  .switch[type="checkbox"].is-small:checked + label:before,
  .switch[type="checkbox"].is-small:checked + label:before {
    background: ${theme.colors.primary} !important;
  }
`;

injectGlobal`${globalStyles}`;
