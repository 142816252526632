import { observable, computed, action } from 'mobx';
import { persist } from 'mobx-persist';
import MODES from '../modes';
// import ls from './localStorage';

export default class {
  @observable ready = false

  @persist @observable readingMode = MODES.DEFAULT

  @observable showMenu = false
  @observable showOptions = false
  @observable showSearch = false

  @observable headerHeight = -1
  @observable footerHeight = -1
  @observable searchInput = null
  @observable scrollY = 0
  @observable scrollDir = null
  @observable lightHeader = false
  @observable showSideNav = false

  constructor(stores) {
    this.stores = stores;
    // this.loadLocalStorage();
  }

  // @action
  // loadLocalStorage() {
  //   ls.load('app', this);
  // }

  @computed
  get routePrefix() {
    return MODES.DEFAULT === this.readingMode ? 'tafsir' : 'reader';
  }

  @computed
  get view() {
    const { location } = this.stores.router;

    if (/^\/(tafsir|reader|single)\//.test(location.pathname)) return 'surah';
    if (/^\/collection\//.test(location.pathname)) return 'collection';
    if (/^\/topic\//.test(location.pathname)) return 'topic';
    if (/^\/$/.test(location.pathname)) return 'home';
    return null;
  }

  @action
  toggleMode(currentSurah) {
    if (['collection', 'topic'].includes(this.view)) {
      return false;
    }

    const newMode = this.readingMode === MODES.DEFAULT ? MODES.READER : MODES.DEFAULT;
    this.readingMode = newMode;

    if (currentSurah) {
      this.stores.router.replace(`/${this.routePrefix}/${currentSurah}`);
    }

    return false;
  }
}
