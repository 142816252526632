import {
  observable,
  computed,
  action,
  // toJS
} from 'mobx';
import {
  find,
  filter,
  startsWith,
  remove
} from 'lodash';
import { api } from '../api';
// import ls from './localStorage';

Array.range = (start, end) => Array.from({ length: ((end - start) + 1) }, (v, k) => k + start);

export default class {
  @observable surahs = null
  @observable juzs = null
  @observable editions = null
  @observable topics = null
  @observable shortcuts = null
  @observable reciters = null
  // @observable stats = {}
  @observable langCodes = null

  @observable loaded = {
    surahs: false,
    juzs: false,
    editions: false,
    topics: false,
    shortcuts: false,
    reciters: false,
    // stats: false,
    // fontSurahnames: false,
  }

  @observable currentSurah = null
  @observable currentVerse = null
  @observable currentCollection = null
  @observable currentTopic = null

  @observable scrolledJuz = null
  @observable scrolledHizb = null
  @observable scrolledRub = null
  @observable scrolledRuku = null
  @observable scrolledPage = null
  @observable scrolledIndex = null
  @observable visibleIndexes = []

  @observable readTotal = 0
  @observable readPercentage = 0

  constructor(stores) {
    this.stores = stores;
    // ls.load('quran', this);
  }

  @action async load() {
    await this.loadSurahs();
    await this.loadJuzs();
    await this.loadEditions();
    await this.loadTopics();
    await this.loadShortcuts();
    await this.loadReciters();
    await this.loadLanguages();
    // await this.loadStats();
  }

  @action
  async loadSurahs(callback = () => {}) {
    if (this.surahs && this.surahs.length > 0) {
      callback(this.surahs);
      return;
    }

    this.surahs = await api.get('/info/surahs').then(res => res.data);

    // ls.save('quran', { surahs: toJS(this.surahs) });

    this.loaded.surahs = true;
    this.setSplashPercentLoaded();
    callback(this.surahs);
  }

  @action
  async loadJuzs(callback = () => {}) {
    if (this.juzs && this.juzs.length > 0) {
      callback(this.juzs);
      return;
    }

    this.juzs = await api.get('/info/juzs').then(res => res.data);

    // ls.save('quran', { juzs: toJS(this.juzs) });

    this.loaded.juzs = true;
    this.setSplashPercentLoaded();
    callback(this.juzs);
  }

  @action
  async loadEditions() {
    if (this.editions && this.editions.length > 0) {
      return;
    }

    this.editions = await api.get('/info/editions').then(res => res.data);

    // ls.save('quran', { editions: toJS(this.editions) });

    this.loaded.editions = true;
    this.setSplashPercentLoaded();
  }

  @action
  async loadTopics() {
    if (this.topics && this.topics.length > 0) {
      return;
    }

    this.topics = await api
      .get('/info/topics')
      .then(res => res.data);

    this.topics = this.processTopics(this.topics);
    // ls.save('quran', { topics: toJS(this.reciters) });

    this.loaded.topics = true;
    this.setSplashPercentLoaded();
  }

  @action
  async loadShortcuts() {
    if (this.shortcuts && this.shortcuts.length > 0) {
      return;
    }

    this.shortcuts = await api
      .get('/info/shortcuts')
      .then(res => res.data);

    // ls.save('quran', { editions: toJS(this.editions) });

    this.loaded.shortcuts = true;
    this.setSplashPercentLoaded();
  }

  @action
  async loadReciters() {
    if (this.reciters && this.reciters.length > 0) {
      return;
    }

    this.reciters = await api
      .get('/info/reciters')
      .then(res => res.data);

    // ls.save('quran', { reciters: toJS(this.reciters) });

    this.loaded.reciters = true;
    this.setSplashPercentLoaded();
  }

  // @action
  // async loadStats() {
  //   if (Object.keys(this.stats).length > 0) {
  //     return;
  //   }

  //   const { totals } = await api.get('/info/stats/totals').then(res => res.data);
  //   const { letters } = await api.get('/info/stats/letters').then(res => res.data);
  //   this.stats = {
  //     totals, letters
  //   };

  //   this.loaded.stats = true;
  //   this.setSplashPercentLoaded();
  // }

  @action
  async loadLanguages() {
    const langCodes = (await import(/* webpackChunkName: 'languages' */ './data/languages.json')).default;

    this.langCodes = langCodes.map((lang) => {
      if (/[,;]/.test(lang.name)) {
        const [name] = lang.name.split(/\s*[,;]\s*/)[0];
        lang.name = name;
      }
      return lang;
    });
  }

  setSplashPercentLoaded() {
    // const loaded = Object.values(this.loaded);
    // const percent = 100 * (filter(loaded, v => v === true).length / loaded.length);

    // document.getElementById('splash-progress').style.width = `${percent}%`;

    // if (percent === 100) {
    //   this.hideSplash();
    // }
  }

  // hideSplash() {
  //   const splash = document.getElementById('splash');
  //   if (splash) {
  //     setTimeout(() => {
  //       if (splash && splash.parentNode) {
  //         splash.parentNode.removeChild(splash);
  //       }

  //       const style = document.getElementById('splash-style');
  //       if (style && style.parentNode) {
  //         style.parentNode.removeChild(style);
  //       }
  //     }, 500);
  //     // splash.classList.add('hide');
  //     // splash.addEventListener('transitionend', () => {
  //     //   setTimeout(() => {
  //     //     if (splash && splash.parentNode) {
  //     //       splash.parentNode.removeChild(splash);
  //     //     }

  //     //     const style = document.getElementById('splash-style');
  //     //     if (style && style.parentNode) {
  //     //       style.parentNode.removeChild(style);
  //     //     }
  //     //   }, 1000);
  //     // }, false);
  //   }
  // }

  // ensureSurahs(timeout = 10000) {
  //   const start = Date.now();

  //   const waitForSurahs = (resolve, reject) => {
  //     if (this.surahs) {
  //       resolve(this.surahs);
  //     } else if (timeout && (Date.now() - start) >= timeout) {
  //       reject(new Error('timeout'));
  //     } else {
  //       setTimeout(waitForSurahs.bind(this, resolve, reject), 30);
  //     }
  //   };

  //   return new Promise(waitForSurahs);
  // }

  getSurahInfo(surahNumber) {
    surahNumber = parseInt(surahNumber, 10);
    const result = find(this.surahs, surah => surah.number === surahNumber);
    return result || null;
  }

  @computed get currentSurahInfo() {
    if (!this.currentSurah) return null;
    return this.getSurahInfo(this.currentSurah);
  }

  @computed get prevSurahInfo() {
    if (!this.currentSurah) return null;
    return this.getSurahInfo(this.currentSurah - 1);
  }

  @computed get nextSurahInfo() {
    if (!this.currentSurah) return null;
    return this.getSurahInfo(this.currentSurah + 1);
  }

  getSurahRoute({ number }) {
    return `/${this.stores.app.routePrefix}/${number}`;
  }

  getLangName(code) {
    const result = find(this.langCodes, { code });
    return result ? result.name : null;
  }

  processVerseKey(verse_key) {
    const info = verse_key.split(':');
    const chapter_id = parseInt(info[0], 10);
    const verse_number = parseInt(info[1], 10);

    return { chapter_id, verse_number };
  }

  processTopics(topics) {
    return topics.map((topic) => {
      topic.title = topic.title.replace(/\s+?\(.+\)/, '');
      if (topic.title_ms) {
        topic.title_ms = topic.title_ms.replace(/\s+?\(.+\)/, '');
      }

      let verse_keys = topic.verse_keys.slice();

      if (this.currentSurah) {
        verse_keys = filter(verse_keys, verse_key => startsWith(verse_key, `${this.currentSurah}:`));
      }

      verse_keys = verse_keys.map((verse_key) => {
        const { chapter_id, verse_number } = this.processVerseKey(verse_key);
        const link = `/single/${chapter_id}/${verse_number}`;

        return {
          key: verse_key,
          chapter_id,
          verse_number,
          link,
        };
      });

      topic.verse_keys = verse_keys;

      return topic;
    });
  }

  @action addVisibleIndex(index) {
    if (!this.visibleIndexes.includes(index)) {
      this.visibleIndexes.push(index);
    }
  }

  @action removeVisibleIndex(index) {
    if (this.visibleIndexes.includes(index)) {
      remove(this.visibleIndexes, n => n === index);
    }
  }

  @action clearVisibleIndexes() {
    this.visibleIndexes = [];
  }
}
