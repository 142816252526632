import { observable, action } from 'mobx';
// import { persist } from 'mobx-persist'
import { orderBy, uniqBy } from 'lodash';
import MODES from '../modes';
import { api, config } from '../api';

export function resetOrConcat(array, newArray, doReset = false) {
  let outArray;

  if (doReset) outArray = newArray;
  else outArray = array.concat(newArray);

  return outArray;
}

export default class {
  @observable verses = []
  @observable pages = []
  @observable words = []
  @observable meta = null
  @observable isCollection = false
  @observable isSingle = false
  @observable quote = null
  @observable loading = false

  constructor(stores) {
    this.stores = stores;
  }

  @action
  async loadVerses(start = null, doReset = true) {
    const { readingMode } = this.stores.app;
    const { activeTranslations } = this.stores.settings;
    const { currentSurah, currentVerse } = this.stores.quran;

    const params = {
      start: start || 1,
      limit: config.limit,
      translations: activeTranslations,
    };

    // Verses and Ayahs
    let url = `/verses/${currentSurah}`;

    if (readingMode === MODES.READER) {
      // const surahInfo = this.stores.quran.getSurahInfo(currentSurah);
      const surah_page = start || undefined;
      // console.log('start page surah', surahInfo.number, surahInfo.english, surah_page);
      url = `/verses/pages/${currentSurah}`;
      params.surah_page = surah_page;
      params.get_pages = 2;
      params.words = 1;
      params.start = undefined;
      params.limit = undefined;
      params.translations = undefined;
    } else if (!start && currentVerse) {
      if (this.isSingle) {
        url = `/verses/keys/${currentSurah}:${currentVerse}`;
      } else {
        // if not single, offset
        // if there is a start-from verse
        params.start = currentVerse;
      }
    }

    this.loading = true;

    const result = await api.get(url, params);

    if (result.ok) {
      const { data } = result;

      if (!data.verses && !data.pages) return;

      if (readingMode !== MODES.READER) {
        const verses = orderBy(uniqBy(data.verses, 'verse_key'), 'verse_number');
        this.verses = resetOrConcat(this.verses, verses, doReset);
      } else {
        // const words = flatten(map(data.verses, 'words'));
        // this.words = resetOrConcat(this.words, words, doReset);
        this.pages = resetOrConcat(this.pages, data.pages, doReset);
      }

      this.meta = data.meta;

      this.loading = false;
    } else {
      throw Error(result.originalError);
    }
  }

  @action
  async loadMore() {
    if (!this.meta) {
      return;
    }

    if (this.meta.next_surah_page) {
      await this.loadVerses(this.meta.next_surah_page, false);
    } else if (this.meta.next_start) {
      await this.loadVerses(this.meta.next_start, false);
    }
  }

  @action
  loadRandomQuote() {
    api
      .get('/verses/quotes', { translations: 'en.sahih' })
      .then(res => res.data)
      .then((quote) => {
        this.quote = quote;
      });
  }

  @action
  clear() {
    this.verses = [];
    this.pages = [];
    this.words = [];
    this.meta = null;
    this.isSingle = false;
    this.quote = null;
  }
}
