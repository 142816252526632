import { observable, action } from 'mobx';
import { persist } from 'mobx-persist';
import {
  find,
  remove,
  orderBy
} from 'lodash';
// import MODES from '../modes'
// import ls from './localStorage';

export default class {
  @persist('list') @observable bookmarks = []

  constructor(stores) {
    this.stores = stores;
    // this.loadLocalStorage();
  }

  // @action
  // loadLocalStorage() {
  //   ls.load('bookmark', this);
  // }

  @action
  addRemoveVerse(verse_key) {
    const { chapter_id, verse_number } = this.stores.quran.processVerseKey(verse_key);

    const created_at = new Date().toISOString();

    const bookmark = find(this.bookmarks, { verse_key });
    if (bookmark) remove(this.bookmarks, { verse_key });
    else {
      this.bookmarks.push({
        type: 'verse', verse_key, chapter_id, verse_number, created_at
      });
      this.bookmarks = orderBy(this.bookmarks, ['chapter_id', 'verse_number']);
    }
  }

  isBookmarked(verse_key) {
    return find(this.bookmarks, { verse_key }) !== undefined;
  }

  @action
  clear() {
    this.bookmarks = [];
  }
}
