export default {
  // primer (github's css)
  // -------- Grays --------
  gray000: '#fafbfc',
  gray100: '#f6f8fa',
  gray200: '#e1e4e8',
  gray300: '#d1d5da',
  gray400: '#959da5',
  gray500: '#6a737d',
  gray600: '#586069',
  gray700: '#444d56',
  gray800: '#2f363d',
  gray900: '#24292e', // body font colo,

  // -------- Blue --------
  blue000: '#f1f8ff',
  blue100: '#dbedff',
  blue200: '#c8e1ff',
  blue300: '#79b8ff',
  blue400: '#2188ff',
  blue500: '#0366d6', // Default:  AA with '#fff,
  blue600: '#005cc5',
  blue700: '#044289',
  blue800: '#032f62',
  blue900: '#05264c', // Passes with 1/2/300 blue,

  // -------- Green --------
  green000: '#f0fff4',
  green100: '#dcffe4',
  green200: '#bef5cb',
  green300: '#85e89d',
  green400: '#34d058',
  green500: '#28a745', // Default. passes AA Larg,
  green600: '#22863a', // Text green, passes AA on '#fff,
  green700: '#176f2c',
  green800: '#165c26',
  green900: '#144620',

  // -------- Yellow --------
  yellow000: '#fffdef',
  yellow100: '#fffbdd',
  yellow200: '#fff5b1',
  yellow300: '#ffea7f',
  yellow400: '#ffdf5d',
  yellow500: '#ffd33d',
  yellow600: '#f9c513',
  yellow700: '#dbab09',
  yellow800: '#b08800',
  yellow900: '#735c0f',

  // -------- Orange --------
  orange000: '#fff8f2',
  orange100: '#ffebda',
  orange200: '#ffd1ac',
  orange300: '#ffab70',
  orange400: '#fb8532',
  orange500: '#f66a0a', // Default. passes AA Large with '#fff,
  orange600: '#e36209',
  orange700: '#d15704',
  orange800: '#c24e00',
  orange900: '#a04100',

  // -------- Red --------
  red000: '#ffeef0',
  red100: '#ffdce0',
  red200: '#fdaeb7',
  red300: '#f97583',
  red400: '#ea4a5a',
  red500: '#d73a49', // Default. passes A,
  red600: '#cb2431',
  red700: '#b31d28',
  red800: '#9e1c23',
  red900: '#86181d',

  // -------- Purple --------
  purple000: '#f5f0ff',
  purple100: '#e6dcfd',
  purple200: '#d1bcf9',
  purple300: '#b392f0',
  purple400: '#8a63d2',
  purple500: '#6f42c1', // passes AA with '#fff,
  purple600: '#5a32a3',
  purple700: '#4c2889',
  purple800: '#3a1d6e',
  purple900: '#29134e',

  // -------- Fades --------
  // Black based on same hue as gray900
  black: '#1b1f23',
  white: '#fff',
};
