import React from 'react';
import styled from 'styled-components';
// import st from 'styled-theming';
import Loadable from 'react-loadable';

// import Spinner from './components/Spinner';
import { theme } from './styles/theme';

// import LogoSvg from './svg/quransayav2.svg';

class LoadingComp extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      try {
        const splash = document.getElementById('splash');
        splash.parentNode.removeChild(splash);
      } catch (e) {
        // console.error(e.message);
      }
    }, 1000);
  }

  render() {
    const { error, retry, pastDelay } = this.props;

    if (error) {
      console.log(error);
      return (
        <LoadingWrapper>
          <div>
            Error: {error.message}{' '}
            <button type="button" className="button is-primary" onClick={retry}>Retry</button>
          </div>
        </LoadingWrapper>
      );
    }

    return null;

    // if (pastDelay) {
    //   // return null;
    //   return (
    //     <LoadingWrapper>
    //       <LogoSvg />
    //     </LoadingWrapper>
    //   );
    // }

    // return null;
  }
}

const Load = (loader) => {
  const LoadableComp = Loadable({
    loader,
    loading: LoadingComp,
    delay: 300
  });
  LoadableComp.preload();

  return LoadableComp;
};

export { Loadable };
export default Load;

export const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5000;
  height: 100vh;
  text-align: center;
  padding: 4rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  user-select: none;
  font-size: 1.25rem;
  background: ${theme.colors.bg};

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  svg {
    height: 3rem;
    fill: ${theme.colors.primary};
  }
`;
