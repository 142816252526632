import { observable, action, computed } from 'mobx';
import { persist } from 'mobx-persist';
import { findIndex } from 'lodash';

import { api, config } from '../api';
// import MODES from '../modes';
import { playErrorHandler } from '../components/Player';

export default class {
  @observable isPlaying = false
  @observable player = null
  @persist @observable activeReciter = 'alafasy';
  @persist @observable showPlayer = true;
  @observable playlist = []
  @observable currentTrackVerseKey = -1
  @observable height = -1

  constructor(stores) {
    this.stores = stores;
    // this.loadLocalStorage();
    this.wbwPlayer = new Audio();
  }

  // @action
  // loadLocalStorage() {
  //   const ls = JSON.parse(localStorage.getItem('player'));
  //   if (ls) {
  //     extend(this, ls);
  //   }
  // }

  @action
  async loadPlaylist() {
    const { view } = this.stores.app;
    const { currentSurah, currentCollection, currentTopic } = this.stores.quran;

    if (!this.activeReciter) {
      return;
    }

    let url;
    if (view === 'surah' && currentSurah) {
      url = `/playlist/${this.activeReciter}/surah/${currentSurah}`;
    } else if (view === 'collection' && currentCollection) {
      url = `/playlist/${this.activeReciter}/collection/${currentCollection}`;
    } else if (view === 'topic' && currentTopic) {
      url = `/playlist/${this.activeReciter}/topic/${currentTopic}`;
    }

    if (!url) {
      return;
    }

    const result = await api.get(url);

    if (result.ok) {
      const { data } = result;
      this.playlist = data;
      console.log('Playlist loaded:', this.playlist.length, 'Reciter:', this.activeReciter);
    } else {
      throw Error('Problems loading playlist.');
    }
  }

  togglePlay(verse_key) {
    if (!this.player || !this.playlist) {
      return;
    }

    const index = findIndex(this.playlist, { verse_key });

    if (index < 0) {
      return;
    }

    const isCurrentTrack = this.currentTrackVerseKey === verse_key;
    const isCurrentTrackPlaying = isCurrentTrack && this.isPlaying;

    if (isCurrentTrackPlaying) {
      this.player.togglePause(true);
    } else if (isCurrentTrack) {
      this.player.togglePause(false);
    } else {
      this.player.skipToIndex(index, true);
    }
  }

  selectTrack(verse_key) {
    if (!this.player || !this.playlist) {
      return;
    }

    const index = findIndex(this.playlist, { verse_key });

    if (!index) {
      return;
    }

    this.player.skipToIndex(index, false);
  }

  playWord = (word) => {
    if (word.audio_file) {
      this.wbwPlayer.src = `${config.cdn_audio_wbw}/${word.audio_file}`;

      try {
        const playPromise = this.wbwPlayer.play();
        // this.timeUpdater();
        if (playPromise && typeof playPromise.catch === 'function') {
          playPromise
            .catch((err) => {
              // AbortError is pretty much always called because we're skipping
              // tracks quickly or hitting pause before a track has a chance to
              // play. It's pretty safe to just ignore these error messages.
              if (err.name !== 'AbortError') {
                return Promise.reject(err);
              }

              return null;
            })
            .catch(playErrorHandler);
        }
      } catch (err) {
        playErrorHandler(err);
      }
    }
  }

  @computed
  get isVisible() {
    const { app } = this.stores;

    return (/(surah|collection|topic)/.test(app.view));
    // && app.readingMode !== MODES.READER;
  }

  @action clear() {
    this.playlist = [];
    this.currentTrackVerseKey = -1;
  }
}
