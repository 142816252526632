import { observable, action, computed } from 'mobx';
import { persist } from 'mobx-persist';
import { reject, isEqual, remove } from 'lodash';
import ls from './localStorage';

export default class {
  @persist('list') @observable activeTranslations = ['en.transliteration', 'en.sahih']
  @persist('list') @observable activities = []
  @persist @observable themeMode = 'light'
  @persist @observable showUthmani = true
  @persist @observable sidebarPosition = 'right'
  @persist @observable showWBWTooltip = true
  @persist @observable highlightPauses = true
  @persist @observable modernEnds = true
  @persist @observable modernRubElHizbs = true
  @persist @observable showRukus = true
  @persist @observable bodySize = 'normal'

  @persist @observable sidebarShowTopics = false

  constructor(stores) {
    this.stores = stores;
    // this.loadLocalStorage();
    this.sidebarPositions = ['right', 'top', 'left'];
  }

  // @action
  // loadLocalStorage() {
  //   const { showSidebar, ...settings } = ls.store.get('settings');
  //   if (showSidebar) {
  //     ls.store.set('settings', settings);
  //   }
  //   // ls.load('settings', this);
  // }

  @action
  saveActivity(url) {
    const activities = this.activities.slice();
    activities.unshift(url);

    this.activities = reject(activities, (object, i) => (
      i > 0 && isEqual(activities[i - 1], object)
    )).slice(0, 5);
  }

  @action
  toggleTranslation(edition) {
    if (this.activeTranslations.includes(edition)) {
      // already selected
      remove(this.activeTranslations, id => id === edition);
    } else {
      // not selected
      this.activeTranslations.push(edition);
    }
  }


  @action
  themeToggle() {
    this.themeMode = this.themeMode === 'light' ? 'dark' : 'light';
  }

  @action
  toggleSidebar = () => {
    const currentIndex = this.sidebarPositions.indexOf(this.sidebarPosition);
    this.sidebarPosition = this.sidebarPositions[(currentIndex + 1) % this.sidebarPositions.length];
  }

  @computed
  get showSidebar() {
    return this.sidebarPosition !== 'top';
  }
}
