import { css } from 'styled-components';
import st from 'styled-theming';
import {
  // rgba,
  darken,
  mix,
  // desaturate,
  // lighten,
  adjustHue
} from 'polished';
import camel from 'camelcase-keys';

import colors, { md, pr } from './colors';

// eslint-disable-next-line
import _sassVars from '!!mk-sass-variables-loader!./scss/variables.scss';

const sassVars = camel(_sassVars);

const {
  primary,
  secondary,
  light,
  radius,
  familySansSerif,
  familySerif,
  familyHeader,
  pauseNoStop,
  pauseMustStop,
  pauseShort,
  tablet,
  desktop,
  widescreen,
  fullhd,
} = sassVars;

const theme = {
  colors: {
    md,
    pr,
    primary,
    secondary,
    light,
    bg: st('mode', { light: pr.white, dark: mix(0.8, pr.black, '#000') }),
    text: st('mode', { light: pr.gray700, dark: pr.gray400 }),
    symbols: primary,
    surahnumber: pr.gray400,
    // navbar: darken(0.05, pr.gray100),
    navbar: st('mode', { light: pr.white, dark: mix(0.8, pr.black, '#000') }),
    border: st('mode', { light: pr.gray200, dark: pr.gray700 }),
    // verseBg: mix(0.96, '#fff', desaturate(0.7, primary))
    verseBg: st('mode', { light: pr.gray000, dark: pr.black }),
    highlightText: darken(0.05, primary),
    playingText: adjustHue(50, darken(0.2, primary)),
    pauseNoStop,
    pauseMustStop,
    pauseShort,
  },

  breakpoints: [
    parseInt(tablet, 10),
    parseInt(desktop, 10),
    parseInt(widescreen, 10),
    parseInt(fullhd, 10),
  ],

  // golden ratio for arabic font size
  goldenRatio: 0.054,

  // others
  familySansSerif,
  familySerif,
  familyHeader,
  radius,
  selectheight: '32px',
  verseLineHeight: 1.7,

  // breakpoints - from bulma
  mobile: '480px',
  tablet,
  desktop,
  widescreen,
  fullhd,
};

const sizes = ['mobile', 'tablet', 'desktop', 'widescreen', 'fullhd'];

// iterate through the sizes and create a media template
// usage:
// const Container = styled.div`
// color: #333;
// ${media.desktop`padding: 0 20px;`}
// ${media.tablet`padding: 0 10px;`}
// ${media.phone`padding: 0 5px;`}
// `
const media = sizes.reduce((accumulator, size) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = theme[size];
  accumulator[size] = (...args) => css`
    @media (max-width: ${emSize}) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

export { theme, colors, media };
