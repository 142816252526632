import React from 'react';
import ReactDOM from 'react-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import { Provider } from 'mobx-react';
import { syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router-dom';
import ReactPiwik from 'react-piwik';

import { stores } from './stores';
import { Loadable } from './loadable';

import './styles/scss/index.scss';
import './styles/injectGlobal';

import App from './App';

Loadable.preloadAll().then(() => {
  console.log('Chunks preloaded.');
});

window.Raven.config('https://549ec80efecd429aa745f90a6a137627@sentry.io/1247490').install();

const piwik = new ReactPiwik({
  url: 'https://analytics.wansal.co',
  siteId: 1,
  trackErrors: true,
  jsFilename: 'js/',
  phpFilename: 'js/'
});

window.noZensmooth = true;

const browserHistory = createBrowserHistory();

const history = syncHistoryWithStore(browserHistory, stores.router);

ReactDOM.render(
  <Provider stores={stores}>
    <Router history={piwik.connectToHistory(history)}>
      <App />
    </Router>
  </Provider>,

  document.getElementById('app')
);
