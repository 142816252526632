// import React, { Component } from 'react';
// import { computed } from 'mobx';
// import { observer, inject } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import { create } from 'mobx-persist';

import AppStore from './app';
import SettingsStore from './settings';
import QuranStore from './quran';
import VerseStore from './verse';
import CollectionStore from './collection';
import FontStore from './font';
import BookmarkStore from './bookmark';
import PlayerStore from './player';

const router = new RouterStore();

const app = new AppStore({ router });
const settings = new SettingsStore({ router, app });
const quran = new QuranStore({ app, settings });
const verse = new VerseStore({ app, settings, quran });
const collection = new CollectionStore({
  app, settings, quran, verse
});
const font = new FontStore({ quran });
const bookmark = new BookmarkStore({ app, settings, quran });
const player = new PlayerStore({
  app, settings, quran, verse
});

const hydrate = create({ storage: localStorage });
hydrate('app', app);
hydrate('settings', settings);
hydrate('bookmark', bookmark);
hydrate('player', player);

const stores = {
  router,
  app,
  settings,
  quran,
  verse,
  collection,
  font,
  bookmark,
  player,
};

// const withStores = (WrappedComponent) => {
//   const componentName = WrappedComponent.name || WrappedComponent.displayName;

//   return (
//     @inject('stores')
//     @observer
//     class extends Component {
//       static displayName = componentName;

//       render() {
//         return (
//           <WrappedComponent {...this.props} />
//         );
//       }
//     }
//   );
// };

export { stores };
