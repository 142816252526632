const config = {
  api_base_dev: 'http://localhost:7788',
  api_base_prod: 'https://api.quransaya.com',

  // cdn: 'https://quranku.s3.wasabisys.com/static',
  // cdn: 'https://qurankuwsb.b-cdn.net/static',
  // cdn: 'https://cdn.quransaya.com/static',
  cdn: 'https://quransaya.b-cdn.net/static',

  // cdn_audio: 'https://quranku.s3.wasabisys.com/vbv',
  cdn_audio: 'https://cdn.quransaya.com/vbv',

  cdn_audio_wbw: 'https://wbw.quransaya.com',

  limit: 15,
  infinite_load: true,
};

config.base = process.env.NODE_ENV === 'development'
  ? config.api_base_dev
  : config.api_base_prod;

config.api_base = `${config.base}/v2`;

export default config;
