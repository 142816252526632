import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import styled, { ThemeProvider } from 'styled-components';
import { Offline } from 'react-detect-offline';
import zenscroll from 'zenscroll';

import { theme, colors } from './styles/theme';

import Load from './loadable';

import Routes from './routes';

const Header = Load(() => import(/* webpackChunkName: 'Header' */ './components/Header'));
const Menu = Load(() => import(/* webpackChunkName: 'Menu' */ './components/Menu'));
const Bookmarks = Load(() => import(/* webpackChunkName: 'Bookmarks' */ './components/Bookmarks'));
const SearchBox = Load(() => import(/* webpackChunkName: 'SearchBox' */ './components/SearchBox'));
const Options = Load(() => import(/* webpackChunkName: 'Options' */ './components/Options'));
const PlayerBar = Load(() => import(/* webpackChunkName: 'PlayerBar' */ './components/PlayerBar'));
const JuzFloater = Load(() => import(/* webpackChunkName: 'InfoFloater' */ './components/InfoFloater'));
const Footer = Load(() => import(/* webpackChunkName: 'Footer' */ './components/Footer'));
const ToTop = Load(() => import(/* webpackChunkName: 'ToTop' */ './routes/ToTop'));

@withRouter
@inject('stores')
@observer
class App extends Component {
  constructor(props) {
    super(props);

    this.ticking = false;
    this.currentScrollY = 0;
  }

  async componentDidMount() {
    const { app, quran, font } = this.props.stores;

    font.loadSurahnames();
    await quran.load();

    app.ready = true;

    window.addEventListener('scroll', this.handleScroll);
    document.body.addEventListener('touchmove', this.handleScroll);

    zenscroll.setup(null, app.headerHeight + 16);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    document.body.removeEventListener('touchmove', this.handleScroll);
  }

  handleScroll = () => {
    const { app } = this.props.stores;
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        app.scrollY = window.scrollY;
        const distance = Math.abs(window.scrollY - this.currentScrollY);

        if (distance > 0 && window.scrollY > this.currentScrollY) {
          this.currentScrollY = window.scrollY;
          app.scrollDir = 'down';
        }
        if (window.scrollY < this.currentScrollY) {
          this.currentScrollY = window.scrollY;
          app.scrollDir = 'up';
        }

        this.ticking = false;
      });
      this.ticking = true;
    }
  }

  render() {
    const { app, settings } = this.props.stores;
    const { headerHeight, footerHeight } = app;
    const { themeMode } = settings;

    return (
      <ToTop>
        <ThemeProvider theme={{ mode: themeMode }}>
          <ThemeProvider theme={theme}>
            <>
              <Helmet>
                <title>QuranSaya &mdash; Welcome</title>
                <body className={themeMode} />
              </Helmet>

              <Offline>
                <OfflineBar>
                  No network connection. QuranSaya needs internet to work.{' '}
                  <span role="img" aria-label="sad">😢</span>
                </OfflineBar>
              </Offline>

              <Header />

              <div id="app-container">
                <Menu />

                <Wrapper id="wrapper" headerHeight={headerHeight} footerHeight={footerHeight}>
                  <Routes />
                  <Footer />
                  <JuzFloater />
                  <Bookmarks />
                </Wrapper>
              </div>


              {/* <Debugger /> */}
              {/* <ScrollToTop /> */}

              <SearchBox />
              <Options />
              <PlayerBar />
            </>
          </ThemeProvider>
        </ThemeProvider>
      </ToTop>
    );
  }
}

const Wrapper = styled.main`
  position: relative;
  min-height: 100vh;
  padding-top: ${p => p.headerHeight}px;
  padding-bottom: ${p => p.footerHeight}px;
`;

const OfflineBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: ${colors.md.orange700};
  color: #fff;
  text-align: center;
  z-index: 20000;
`;

export default hot(module)(App);
