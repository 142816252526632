import store from 'store2';
import { extend } from 'lodash';

const loadStore = (key, obj) => {
  const ls = store.get(key);
  if (ls) {
    extend(obj, ls);
  }
};

const saveStore = (key, obj) => {
  store.add(key, obj);
};

export default { load: loadStore, save: saveStore, store };
