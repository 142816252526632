import { observable, action } from 'mobx';
import { orderBy, extend, uniqBy } from 'lodash';
import { api, config } from '../api';
import { resetOrConcat } from './verse';

export default class {
  // title & verse_keys for both collection and topic
  @observable title = null
  @observable verse_keys = null
  // these three only for collection
  @observable title_ar = null
  @observable description = null
  @observable description_ms = null
  @observable type = null

  constructor(stores) {
    this.stores = stores;
  }

  @action
  async loadVerses(start = 1, doReset = true) {
    const {
      app,
      settings,
      quran,
      verse
    } = this.stores;
    const { view } = app;
    const { activeTranslations } = settings;

    console.log(`Load ${view}`);

    let slug;
    let endpoint;
    if (view === 'collection') {
      slug = quran.currentCollection;
      endpoint = 'collections';
      this.type = 'collection';
    } else if (view === 'topic') {
      slug = quran.currentTopic;
      endpoint = 'topics';
      this.type = 'topic';
    }

    // Verses and Ayahs
    const params = {
      start,
      translations: activeTranslations,
      limit: config.limit
    };

    verse.loading = true;

    const res = await api
      .get(`/verses/${endpoint}/${slug}`, params);

    if (res.ok) {
      const result = res.data;

      if (!result.verses) return;

      const { verses, ...collection } = result;
      extend(this, collection);

      verse.verses = orderBy(uniqBy(resetOrConcat(verse.verses, verses, doReset), 'verse_key'), ['chapter_id', 'verse_number']);
      verse.meta = result.meta;

      verse.loading = false;

      console.log(`${view} verses loaded: `, verse.verses.length);
    } else {
      throw Error('No such thing.');
    }
  }

  @action
  async loadMore() {
    const { verse } = this.stores;

    if (!verse.meta) {
      return;
    }

    if (verse.meta.next_start) {
      await this.loadVerses(verse.meta.next_start, false);
    }
  }

  @action
  clear() {
    this.stores.verse.verses = [];
    this.stores.verse.meta = null;
    this.verse_keys = null;
    this.title = null;
    this.title_ar = null;
    this.description = null;
    this.description_ms = null;
    this.type = null;
  }
}
