// STOLEN FROM react-responsive-audio-player

import React from 'react';
import PropTypes from 'prop-types';
import { findIndex } from 'lodash';
import styled from 'styled-components';
import cx from 'classnames';

const log = console.log.bind(console); // eslint-disable-line
const logError = console.error ? console.error.bind(console) : log; // eslint-disable-line
const logWarning = console.warn ? console.warn.bind(console) : log; // eslint-disable-line

let nextControlKey = 0;
function getNextControlKey() {
  nextControlKey += 1;
  return nextControlKey.toString();
}

export function playErrorHandler(err) {
  logError(err);
  if (err.name === 'NotAllowedError') {
    const warningMessage = `Audio playback failed at ${new Date().toLocaleTimeString()}! (Perhaps autoplay is disabled in this browser.)`;
    logWarning(warningMessage);
  }
}

/* converts given number of seconds to standard time display format
 * http://goo.gl/kEvnKn
 */
function convertToTime(number) {
  const mins = Math.floor(number / 60);
  const secs = (number % 60).toFixed();
  return `${mins < 10 ? '0' : ''}${mins}:${secs < 10 ? '0' : ''}${secs}`;
}

// Existing Media Session API implementations have default handlers
// for play/pause, and may yield unexpected behavior if custom
// play/pause handlers are defined - so let's leave them be.
const supportableMediaSessionActions = [
  'previoustrack',
  'nexttrack',
  'seekbackward',
  'seekforward',
];

// BEGIN PRIVATE CONTROL COMPONENTS

const SkipButton = ({
  disabled, hidden, back, onClick
}) => (
  <div
    id="skip_button"
    className={cx('skip_button audio_button', { disabled, hidden })}
    onClick={onClick}
  >
    <div className="skip_button_inner">
      <i className={cx('far fa-fast-forward', { 'fa-flip-horizontal': back })} />
    </div>
  </div>
);

const BackSkipButton = ({ audioPlayer }) => (
  <SkipButton
    audioPlayer={audioPlayer}
    disabled={!audioPlayer.props.cycle && audioPlayer.currentTrackIndex - 1 < 0}
    hidden={audioPlayer.props.hideBackSkip}
    back
    onClick={audioPlayer.backSkip}
  />
);

const ForwardSkipButton = ({ audioPlayer }) => (
  <SkipButton
    audioPlayer={audioPlayer}
    disabled={!audioPlayer.props.cycle
      && audioPlayer.currentTrackIndex + 1 >= audioPlayer.props.playlist.length}
    hidden={audioPlayer.props.hideForwardSkip}
    back={false}
    onClick={audioPlayer.skipToNextTrack}
  />
);

const PlayPauseButton = ({ audioPlayer }) => (
  <div
    id="play_pause_button"
    className={cx('play_pause_button audio_button', {
      paused: audioPlayer.state.paused,
      disabled: !audioPlayer.props.playlist || audioPlayer.props.playlist.length === 0,
    })}
    onClick={audioPlayer.togglePause}
  >
    <div className="play_pause_inner">
      <i className={cx('fal', audioPlayer.state.paused ? 'fa-play-circle' : 'fa-pause-circle')} />
    </div>
  </div>
);

const PlaybackRateButton = ({ audioPlayer }) => (
  <div
    id="playback_rate_button"
    className={cx('playback_rate_button audio_button', {
      disabled: !audioPlayer.props.playlist || audioPlayer.props.playlist.length === 0,
    })}
    onClick={audioPlayer.togglePlaybackRate}
  >
    <div className="playback_rate_inner">
      {audioPlayer.state.playbackRate}<span>×</span>
    </div>
  </div>
);

const Spacer = () => <div className="spacer" />;

const AudioProgressDisplay = props => (
  <div
    id="audio_progress_container"
    className="audio_progress_container"
    ref={props.onRef}
    onMouseDown={props.onMouseTouchStart}
    onTouchStart={props.onMouseTouchStart}
  >
    <div
      id="audio_progress"
      className="audio_progress"
      style={{ width: props.progressBarWidth }}
    />
    <div id="audio_progress_overlay" className="audio_progress_overlay">
      <div className="audio_info_marquee">
        <div id="audio_info" className="audio_info noselect" draggable="false">
          {props.displayText}
        </div>
      </div>
      <div id="audio_time_progress" className="audio_time_progress noselect" draggable="false">
        {props.remainingTime}
      </div>
    </div>
  </div>
);

const AudioProgress = props => (
  <AudioProgressDisplay
    {...props}
    onMouseTouchStart={props.audioPlayer.adjustDisplayedTime}
    onRef={(ref) => { props.audioPlayer.audioProgressContainer = ref; }}
  />
);

const keywordToControlComponent = {
  backskip: BackSkipButton,
  forwardskip: ForwardSkipButton,
  playpause: PlayPauseButton,
  playbackrate: PlaybackRateButton,
  spacer: Spacer,
  progressdisplay: AudioProgressDisplay,
  progress: AudioProgress,
};

// END PRIVATE CONTROL COMPONENTS

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);

    /* true if the user is currently dragging the mouse
     * to seek a new track position
     */
    this.seekInProgress = false;
    // index matching requested track (whether track has loaded or not)
    this.currentTrackIndex = 0;

    this.defaultState = {
      /* activeTrackIndex will change to match
       * this.currentTrackIndex once metadata has loaded
       */
      activeTrackIndex: -1,
      // indicates whether audio player should be paused
      paused: true,
      /* elapsed time for current track, in seconds -
       * DISPLAY ONLY! the actual elapsed time may
       * not match up if we're currently seeking, since
       * the new time is visually previewed before the
       * audio seeks.
       */
      displayedTime: 0,
      playbackRate: 1,
    };

    this.state = this.defaultState;

    this.preloadedTracks = [];

    // set of keys to use in controls render
    this.controlKeys = props.controls.map(getNextControlKey);

    // html audio element used for playback
    this.audio = new Audio();
    this.audioProgressContainer = null;

    // event listeners to apm-cdd on mount and remove on unmount
    // this.setAudioElementRef = this.setAudioElementRef.bind(this);
    this.backSkip = this.backSkip.bind(this);
    this.skipToNextTrack = this.skipToNextTrack.bind(this);
    this.skipToIndex = this.skipToIndex.bind(this);
    this.togglePause = this.togglePause.bind(this);
    this.togglePlaybackRate = this.togglePlaybackRate.bind(this);
    this.adjustDisplayedTime = this.adjustDisplayedTime.bind(this);
    this.seekReleaseListener = e => this.seek(e);
    this.audioPlayListener = () => {
      this.setState({ paused: false });
      this.stealMediaSession();
      this.props.onPlayPause(true);
      this.props.onTrackChange({
        index: this.currentTrackIndex,
        track: this.props.playlist[this.currentTrackIndex]
      });
      this.preloadTrack();
    };
    this.audioPauseListener = () => {
      this.setState({ paused: true });
      this.props.onPlayPause(false);
    };
    this.audioEndListener = () => {
      this.skipToNextTrack();
      // const gapLengthInSeconds = this.props.gapLengthInSeconds || 0;
      // clearTimeout(this.gapLengthTimeout);
      // this.gapLengthTimeout =
      //   setTimeout(() => this.skipToNextTrack(), gapLengthInSeconds * 1000);
    };
    this.audioStallListener = () => this.togglePause(true);
    this.audioTimeUpdateListener = () => this.handleTimeUpdate();
    this.audioMetadataLoadedListener = () => {
      this.setState({
        activeTrackIndex: this.currentTrackIndex,
      });
    };
    // this.audioProgressListener = () => this.handleProgress();
  }

  componentDidMount() {
    // add event listeners bound outside the scope of our component
    window.addEventListener('mousemove', this.adjustDisplayedTime);
    document.addEventListener('touchmove', this.adjustDisplayedTime);
    window.addEventListener('mouseup', this.seekReleaseListener);
    document.addEventListener('touchend', this.seekReleaseListener);

    // add event listeners on the audio element
    this.audio.preload = 'metadata';
    this.audio.addEventListener('play', this.audioPlayListener);
    this.audio.addEventListener('pause', this.audioPauseListener);
    this.audio.addEventListener('ended', this.audioEndListener);
    this.audio.addEventListener('stalled', this.audioStallListener);
    this.audio.addEventListener('timeupdate', this.audioTimeUpdateListener);
    this.audio.addEventListener('loadedmetadata', this.audioMetadataLoadedListener);
    // this.audio.addEventListener('progress', this.audioProgressListener);
    this.addMediaEventListeners(this.props.onMediaEvent);

    if (this.props.playlist && this.props.playlist.length) {
      this.updateSource();
      if (this.props.autoplay) {
        const delay = this.props.autoplayDelayInSeconds || 0;
        clearTimeout(this.delayTimeout);
        this.delayTimeout = setTimeout(() => this.togglePause(false), delay * 1000);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    // Update media event listeners that may have changed
    this.removeMediaEventListeners(this.props.onMediaEvent);
    this.addMediaEventListeners(nextProps.onMediaEvent);

    const oldControls = [...this.props.controls];
    this.controlKeys = nextProps.controls.map((control) => {
      const matchingIndex = oldControls.indexOf(control);
      if (matchingIndex !== -1 && oldControls[matchingIndex]) {
        oldControls[matchingIndex] = null;
        return this.controlKeys[matchingIndex];
      }
      return getNextControlKey();
    });

    const newPlaylist = nextProps.playlist;
    if (!newPlaylist || !newPlaylist.length) {
      if (this.audio) {
        this.audio.src = '';
      }
      this.currentTrackIndex = 0;
      return this.setState(this.defaultState);
    }

    const oldPlaylist = this.props.playlist;

    const currentTrackUrl = ((oldPlaylist || [])[this.currentTrackIndex] || {}).url;

    if (currentTrackUrl) {
      const match = currentTrackUrl.match(/(\d{6})\.mp3/);

      if (match) {
        this.currentTrackIndex = findIndex(
          newPlaylist, track => track.url && track.url.includes(match[1])
        );
      } else {
        this.currentTrackIndex = findIndex(
          newPlaylist, track => track.url && currentTrackUrl === track.url
        );
      }
    }

    /* if the track we're already playing is in the new playlist, update the
     * activeTrackIndex.
     */
    if (this.currentTrackIndex !== -1) {
      this.setState({
        activeTrackIndex: this.currentTrackIndex,
      });
      this.props.onTrackChange({
        index: this.currentTrackIndex,
        track: nextProps.playlist[this.currentTrackIndex]
      });
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    /* if we loaded a new playlist and reset the current track marker, we
     * should load up the first one.
     */
    if (this.currentTrackIndex === -1) {
      this.skipToNextTrack(false);
    }
    if (prevProps !== this.props && !this.audio.paused) {
      // update running media session based on new props
      this.stealMediaSession();
    }
  }

  componentWillUnmount() {
    // remove event listeners bound outside the scope of our component
    window.removeEventListener('mousemove', this.adjustDisplayedTime);
    document.removeEventListener('touchmove', this.adjustDisplayedTime);
    window.removeEventListener('mouseup', this.seekReleaseListener);
    document.removeEventListener('touchend', this.seekReleaseListener);

    // remove event listeners on the audio element
    this.audio.removeEventListener('play', this.audioPlayListener);
    this.audio.removeEventListener('pause', this.audioPauseListener);
    this.audio.removeEventListener('ended', this.audioEndListener);
    this.audio.removeEventListener('stalled', this.audioStallListener);
    // this.audio.removeEventListener('timeupdate', this.audioTimeUpdateListener);
    this.audio.removeEventListener('loadedmetadata', this.audioMetadataLoadedListener);
    // this.audio.removeEventListener('progress', this.audioProgressListener);
    this.removeMediaEventListeners(this.props.onMediaEvent);
    clearTimeout(this.gapLengthTimeout);
    clearTimeout(this.delayTimeout);

    clearInterval(this.timeUpdateInterval);

    // pause the audio element before we unmount
    this.audio.pause();
  }

  setAudioElementRef(ref) {
    this.audio = ref;

    try {
      this.audio.volume = 1;
      if (typeof this.props.audioElementRef === 'function') {
        this.props.audioElementRef(this.audio);
      }
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  // timeUpdater = () => {
  //   this.handleTimeUpdate();
  //   this.ticker = window.requestAnimationFrame(this.timeUpdater);
  // }

  // stopTimeUpdater() {
  //   window.cancelAnimationFrame(this.ticker);
  //   this.ticker = null;
  // }

  togglePlaybackRate = () => {
    if (!this.audio) {
      return;
    }
    if (!this.props.playlist || !this.props.playlist.length) {
      return;
    }
    const { playbackRates } = this.props;
    const { playbackRate } = this.state;
    const totalRates = playbackRates.length;
    const rateIndex = findIndex(playbackRates, rate => playbackRate === rate);
    this.setState({ playbackRate: playbackRates[(rateIndex + 1) % totalRates] }, () => {
      this.audio.playbackRate = this.state.playbackRate;
    });
  }

  addMediaEventListeners(mediaEvents) {
    if (!mediaEvents) {
      return;
    }
    Object.keys(mediaEvents).forEach((type) => {
      if (typeof mediaEvents[type] !== 'function') {
        return;
      }
      this.audio.addEventListener(type, mediaEvents[type]);
    });
  }

  removeMediaEventListeners(mediaEvents) {
    if (!mediaEvents) {
      return;
    }
    Object.keys(mediaEvents).forEach((type) => {
      if (typeof mediaEvents[type] !== 'function') {
        return;
      }
      this.audio.removeEventListener(type, mediaEvents[type]);
    });
  }

  stealMediaSession() {
    // eslint-disable-next-line
    if (!(window.MediaSession && navigator.mediaSession instanceof MediaSession)) {
      return;
    }
    // eslint-disable-next-line
    navigator.mediaSession.metadata = new MediaMetadata(this.props.playlist[this.currentTrackIndex]);
    supportableMediaSessionActions
      .map((action) => {
        if (this.props.supportedMediaSessionActions.indexOf(action) === -1) {
          return null;
        }
        const seekLength = this.props.mediaSessionSeekLengthInSeconds;
        switch (action) {
          case 'play':
            return this.togglePause.bind(this, false);
          case 'pause':
            return this.togglePause.bind(this, true);
          case 'previoustrack':
            return this.backSkip;
          case 'nexttrack':
            return this.skipToNextTrack;
          case 'seekbackward':
            return () => { this.audio.currentTime -= seekLength; };
          case 'seekforward':
            return () => { this.audio.currentTime += seekLength; };
          default:
            return undefined;
        }
      })
      .forEach((handler, i) => {
        navigator.mediaSession.setActionHandler(supportableMediaSessionActions[i], handler);
      });
  }

  preloadTrack(preloadAfter = 1) {
    if (this.currentTrackIndex !== -1 && this.props.playlist && this.props.playlist.length) {
      const index = this.currentTrackIndex;

      for (let i = index; i <= index + preloadAfter; i += 1) {
        if (this.props.playlist[i]) {
          const track = this.props.playlist[i];

          if (!this.preloadedTracks.includes(track.url)) {
            const audio = new Audio();
            // once this file loads, it will call loadedAudio()
            // the file will be kept by the browser as cache
            // audio.addEventListener('canplaythrough', loadedAudio, false);
            audio.src = track.url;
            this.preloadedTracks.push(track.url);
          }
        }
      }
    }
  }

  togglePause(value) {
    if (!this.audio) {
      return;
    }
    const pause = typeof value === 'boolean' ? value : !this.state.paused;
    if (pause) {
      // this.stopTimeUpdater();
      this.audio.pause();
      return;
    }
    if (!this.props.playlist || !this.props.playlist.length) {
      return;
    }
    try {
      const playPromise = this.audio.play();
      // this.timeUpdater();
      if (playPromise && typeof playPromise.catch === 'function') {
        playPromise
          .catch((err) => {
            // AbortError is pretty much always called because we're skipping
            // tracks quickly or hitting pause before a track has a chance to
            // play. It's pretty safe to just ignore these error messages.
            if (err.name !== 'AbortError') {
              return Promise.reject(err);
            }

            return null;
          })
          .catch(playErrorHandler);
      }
    } catch (err) {
      playErrorHandler(err);
    }
  }

  skipToIndex(index, shouldPlay) {
    if (!this.audio) {
      return;
    }
    if (!this.props.playlist || !this.props.playlist.length) {
      return;
    }
    if (index < 0 || index >= this.props.playlist.length) {
      return;
    }
    this.currentTrackIndex = index;
    const shouldPause = (typeof shouldPlay === 'boolean' ? !shouldPlay : false);
    if (shouldPause) {
      this.togglePause(true);
    }
    this.setState(
      {
        activeTrackIndex: -1,
        displayedTime: 0,
      },
      () => {
        setTimeout(() => {
          // run asynchronously so "pause" event has time to process
          this.updateSource();
          if (!shouldPause) {
            this.togglePause(false);
          }
        });
      }
    );
  }

  skipToNextTrack(shouldPlay) {
    if (!this.audio) {
      return;
    }
    if (!this.props.playlist || !this.props.playlist.length) {
      return;
    }
    let i = this.currentTrackIndex + 1;
    if (i >= this.props.playlist.length) {
      if (!this.props.cycle) {
        return;
      }
      i = 0;
    }
    this.currentTrackIndex = i;
    const shouldPauseOnCycle = !this.props.cycle && this.currentTrackIndex === 0;
    const shouldPause = shouldPauseOnCycle
      || (typeof shouldPlay === 'boolean' ? !shouldPlay : false);
    if (shouldPause) {
      this.togglePause(true);
    }
    this.setState(
      {
        activeTrackIndex: -1,
        displayedTime: 0,
      },
      () => {
        setTimeout(() => {
          // run asynchronously so "pause" event has time to process
          this.updateSource();
          if (!shouldPause) {
            this.togglePause(false);
          }
        });
      }
    );
  }

  backSkip() {
    if (!this.props.playlist || !this.props.playlist.length) {
      return;
    }
    let { stayOnBackSkipThreshold } = this.props;
    if (isNaN(stayOnBackSkipThreshold)) { // eslint-disable-line
      stayOnBackSkipThreshold = 5;
    }
    if (this.audio.currentTime >= stayOnBackSkipThreshold) {
      this.audio.currentTime = 0;
      return;
    }
    let i = this.currentTrackIndex - 1;
    if (i < 0) {
      if (!this.props.cycle) {
        return;
      }
      i = this.props.playlist.length - 1;
    }
    this.currentTrackIndex = i - 1;
    this.skipToNextTrack();
  }

  updateSource() {
    this.audio.volume = 1;
    this.audio.src = this.props.playlist[this.currentTrackIndex].url;
    this.audio.playbackRate = this.state.playbackRate;
  }

  handleTimeUpdate() {
    if (!this.seekInProgress && this.audio) {
      this.props.onTimeUpdate({
        currentTime: this.audio.currentTime,
        duration: this.audio.duration
      });

      this.setState({
        displayedTime: this.audio.currentTime,
      });

      // this.audio.volume = this.createFades();
    }
  }

  // handleProgress() {
  //   if (this.audio) {
  //     const ranges = [];
  //     for (let i = 0; i < this.audio.buffered.length; i++) {
  //       ranges.push([
  //         this.audio.buffered.start(i),
  //         this.audio.buffered.end(i)
  //       ]);
  //     }

  //     console.log(ranges);
  //   }
  // }

  // createFades(fadeStart = 0.15, fadeEnd = 0.5) {
  //   const { currentTime, duration } = this.audio;

  //   if (duration) {
  //     let volume = 1;
  //     if (currentTime <= fadeStart) {
  //       volume = currentTime / fadeStart;
  //     } else if (currentTime >= duration - fadeEnd) {
  //       volume = (duration - currentTime) / fadeEnd;
  //     }
  //     return Math.max(0, Math.min(1, volume));
  //   }

  //   return 1;
  // }

  adjustDisplayedTime(event) {
    if (!this.props.playlist || !this.props.playlist.length || this.props.disableSeek) {
      return;
    }
    // make sure we don't select stuff in the background while seeking
    if (event.type === 'mousedown' || event.type === 'touchstart') {
      this.seekInProgress = true;
      document.body.classList.add('noselect');
    } else if (!this.seekInProgress) {
      return;
    }
    /* we don't want mouse handlers to receive the event
     * after touch handlers if we're seeking.
     */
    event.preventDefault();
    const boundingRect = this.audioProgressContainer.getBoundingClientRect();
    const isTouch = event.type.slice(0, 5) === 'touch';
    const pageX = isTouch ? event.targetTouches.item(0).pageX : event.pageX;
    const position = pageX - boundingRect.left - document.body.scrollLeft;
    const containerWidth = boundingRect.width;
    const progressPercentage = Math.max(0, Math.min(1, position / containerWidth));
    this.setState({
      displayedTime: progressPercentage * this.audio.duration,
    });
  }

  seek(event) {
    /* this function is activated when the user lets
     * go of the mouse, so if .noselect was applied
     * to the document body, get rid of it.
     */
    document.body.classList.remove('noselect');
    if (!this.seekInProgress) {
      return;
    }
    /* we don't want mouse handlers to receive the event
     * after touch handlers if we're seeking.
     */
    event.preventDefault();
    this.seekInProgress = false;
    const { displayedTime } = this.state;
    if (isNaN(displayedTime)) { // eslint-disable-line
      return;
    }
    this.audio.currentTime = displayedTime;
  }

  render() {
    const activeIndex = this.state.activeTrackIndex;
    const displayText = this.props.getDisplayText(this.props.playlist[activeIndex]);

    const { displayedTime, playbackRate } = this.state;
    const duration = (this.audio && this.audio.duration) || 0;

    const elapsedTime = convertToTime(displayedTime);
    const fullTime = convertToTime(duration);
    const remainingTime = `-${convertToTime(duration - displayedTime)}`;
    const timeRatio = `${elapsedTime} / ${fullTime}`;

    const progressBarWidth = `${(displayedTime / duration) * 100}%`;

    return (
      <Player id="audio_player" className="audio_player" style={this.props.style}>
        {/* eslint-disable-next-line */}
        {/* <audio ref={this.setAudioElementRef} /> */}
        {this.props.controls.map((controlKeyword, index) => {
          const controlProps = controlKeyword === 'progress'
            || controlKeyword === 'progressdisplay'
            ? {
              displayText,
              timeRatio,
              remainingTime,
              progressBarWidth,
              playbackRate,
              audioPlayer: this
            }
            : { audioPlayer: this };
          const Control = keywordToControlComponent[controlKeyword] || null;
          return <Control {...controlProps} key={this.controlKeys[index]} />;
        })}
      </Player>
    );
  }
}

AudioPlayer.propTypes = {
  playlist: PropTypes.array,
  controls: PropTypes.arrayOf(PropTypes.oneOf([
    'playpause',
    'backskip',
    'forwardskip',
    'playbackrate',
    'progress',
    'progressdisplay',
    'spacer',
  ])),
  autoplay: PropTypes.bool,
  autoplayDelayInSeconds: PropTypes.number,
  gapLengthInSeconds: PropTypes.number,
  hideBackSkip: PropTypes.bool,
  hideForwardSkip: PropTypes.bool,
  cycle: PropTypes.bool,
  disableSeek: PropTypes.bool,
  stayOnBackSkipThreshold: PropTypes.number,
  supportedMediaSessionActions: PropTypes.arrayOf(PropTypes.oneOf([
    'play',
    'pause',
    'previoustrack',
    'nexttrack',
    'seekbackward',
    'seekforward',
  ]).isRequired).isRequired,
  mediaSessionSeekLengthInSeconds: PropTypes.number.isRequired,
  getDisplayText: PropTypes.func.isRequired,
  style: PropTypes.object,
  onMediaEvent: PropTypes.object,
  audioElementRef: PropTypes.func,
  onTrackChange: PropTypes.func,
  onPlayPause: PropTypes.func,
  onTimeUpdate: PropTypes.func,
  playbackRates: PropTypes.array,
};

AudioPlayer.defaultProps = {
  cycle: true,
  controls: ['spacer', 'backskip', 'playpause', 'forwardskip', 'playbackrate', 'spacer', 'progress'],
  supportedMediaSessionActions: ['play', 'pause', 'previoustrack', 'nexttrack'],
  mediaSessionSeekLengthInSeconds: 10,
  getDisplayText: function getDisplayText(track) {
    if (!track) {
      return '';
    }
    if (track.displayText) {
      // TODO: Remove this check when support for the displayText prop is gone.
      return track.displayText;
    }
    if (track.title && track.artist) {
      return `${track.artist} - ${track.title}`;
    }
    return track.title || track.artist || track.album || '';
  },
  onTrackChange: () => {},
  onPlayPause: () => {},
  onTimeUpdate: () => {},
  playbackRates: [0.75, 1, 1.25, 1.5],
};

export default AudioPlayer;

const Player = styled.div``;
