import { create } from 'apisauce';
import qs from 'qs';
import { isArray } from 'lodash';
import config from './config';

const api = create({
  baseURL: config.api_base,
  paramsSerializer: (params) => {
    if (isArray(params.translations)) params.translations = params.translations.join(',');

    return qs.stringify(params, { arrayFormat: 'brackets' });
  },
});

export { api, config };
