import { observable, action } from 'mobx';
import WebFont from 'webfontloader';
// import FontFaceObserver from 'fontfaceobserver';

import config from '../config';
import fontNames from '../font-names.json';

export default class {
  @observable loadedFonts = []
  @observable readyFonts = []

  constructor(stores) {
    this.stores = stores;
  }

  loadSurahnames() {
    WebFont.load({
      custom: {
        families: ['surahname'],
        urls: [`${config.cdn}/surahnames/surahnames` + '.css'],
      },
      // fontactive: () => {
      //   this.stores.quran.loaded.fontSurahnames = true;
      // },
      classes: false
    });
  }

  @action loadFont(page, single = false, callback = () => {}) {
    const maxPage = 604;
    const pagesToLoad = single ? [page] : Array.range(page, Math.min(maxPage, page + 3));

    pagesToLoad.forEach((pg) => {
      if (!this.isLoaded(pg)) {
        this.appendFontCSS(pg);
        WebFont.load({
          custom: {
            families: [`p${pg}`],
            // urls: [`${config.cdn}/pgs/base64/${fontNames[pg]}` + '.css'],
          },
          fontactive: (familyName, fvd) => {
            callback(familyName, fvd);
            this.readyFonts.push(pg);
            // console.log('Font loaded: page', pg);
          },
          classes: false
        });
        this.loadedFonts.push(pg);
      }

      // if (this.isReady(pg)) {
      //   console.log('Font already preloaded: page', pg);
      // }
    });
  }

  isLoaded(page) {
    return this.loadedFonts.includes(page) || this.readyFonts.includes(page);
  }

  isReady(page) {
    return this.readyFonts.includes(page);
  }

  appendFontCSS(page) {
    const url = `${config.cdn}/pages/fonts/${fontNames[page]}`;
    const node = document.createElement('style');
    node.innerHTML = `@font-face{font-family:"p${page}";src:url("${url}.woff") format('woff');}`;
    document.body.appendChild(node);
  }
}
